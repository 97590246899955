import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';

import Img from 'components/ui/Img';

import styles from './CardCommitment.module.scss';

const iconMap = {
    'stewardship/thriving-farms': 'barn',
    'stewardship/healthful-cows': 'cow',
    'stewardship/inspired-consumers': 'ribbon',
    'stewardship/enduring-ecosystems': 'hills',
    'stewardship/fulfilled-employees': 'people',
    'stewardship/enriched-communities': 'heartHandshake',
};

const CardCommitment = ({
    title,
    slug,
    description,
    icon,
    image,
    imageLarge,
}) => {
    if ((title && slug && description && image && imageLarge, icon)) {
        return (
            <Link className={styles.linkWrapper} href={`/${slug}`}>
                <article className={styles.commitmentCard}>
                    <div className={styles.imageWrapper}>
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            fallbackImageWidth={732}
                            alt={image.description || ''}
                            width={image?.file.details?.image?.width}
                            height={image?.file.details?.image?.height}
                            customSources={[
                                {
                                    breakpoint: 1024,
                                    src: imageLarge.file.url,
                                    imageWidth: 465,
                                },
                                {
                                    breakpoint: 768,
                                    src: imageLarge.file.url,
                                    imageWidth: 912,
                                },
                                {
                                    src: image.file.url,
                                    imageWidth: 732,
                                },
                            ]}
                        />
                        <Text className={styles.title} baseTheme="displaySmall">
                            {title}
                        </Text>
                    </div>
                    <div className={styles.contentWrapper}>
                        <div
                            className={cx(
                                styles.iconWrapper,
                                styles[`icon--${iconMap[slug]}`]
                            )}
                        >
                            <Icon src={icon?.icon?.file?.url} />
                        </div>
                        <div className={styles.description}>
                            <Text baseTheme="bodyMedium">{description}</Text>
                        </div>
                        <Text
                            baseTheme="labelLarge"
                            className={styles.commitmentLink}
                        >
                            View Commitment
                        </Text>
                    </div>
                </article>
            </Link>
        );
    }

    return null;
};

CardCommitment.propTypes = {
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default CardCommitment;
