import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';
import Link from 'components/ui/Link';

import Img from 'components/ui/Img';

import styles from './CardRecipeGrid.module.scss';

const CardRecipeGrid = ({
    image,
    category,
    cookTime,
    prepTime,
    slug,
    title,
}) => {
    return (
        <article className={styles.root}>
            <Link href={`/recipes/${slug}`} className={styles.inner}>
                <figure className={styles.aspectBox}>
                    {image?.file?.url && (
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            alt={image.description || ''}
                            width={image?.file.details?.image?.width}
                            height={image?.file.details?.image?.height}
                            customSources={[
                                {
                                    breakpoint: 1440,
                                    src: image.file.url,
                                    imageWidth: 1440 * 0.5,
                                },
                                {
                                    breakpoint: 1024,
                                    src: image.file.url,
                                    imageWidth: 1024 * 0.5,
                                },
                                {
                                    breakpoint: 768,
                                    src: image.file.url,
                                    imageWidth: 768 * 0.75,
                                },
                                {
                                    src: image.file.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
                </figure>
                <div className={styles.infoWrapper}>
                    <div className={styles.infoLockup}>
                        <SvgIcon className={styles.timeIcon} iconType="clock" />
                        <Text
                            className={styles.timeLabel}
                            baseTheme="labelMedium"
                            themes={{ large: 'labelLarge' }}
                        >
                            {prepTime + cookTime}MIN
                        </Text>
                        <Text
                            className={styles.separator}
                            baseTheme="labelSmall"
                        >
                            |
                        </Text>
                        <Text
                            className={styles.category}
                            baseTheme="labelMedium"
                            themes={{ large: 'labelLarge' }}
                        >
                            {category.title}
                        </Text>
                    </div>
                    <div className={styles.headlineWrapper}>
                        <Text
                            className={styles.headline}
                            baseTheme="headingSmall"
                            themes={{ xxLarge: 'headingMedium' }}
                            as="h5"
                        >
                            {title}
                        </Text>
                    </div>
                </div>
            </Link>
        </article>
    );
};

CardRecipeGrid.propTypes = {
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    category: PropTypes.shape({
        title: PropTypes.string,
    }),
    cookTime: PropTypes.number,
    creator: PropTypes.string,
    prepTime: PropTypes.number,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string,
};

CardRecipeGrid.defaultProps = {
    image: {},
    category: {
        title: '',
    },
    cookTime: 0,
    creator: '',
    prepTime: 0,
    title: '',
};

export default CardRecipeGrid;
