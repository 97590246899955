import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Block from 'components/blocks/';
import CardProductCategory from 'components/cards/CardProductCategory';
import TextEyebrow from 'components/ui/TextEyebrow';
import Link from 'components/ui/Link';
import Img from 'components/ui/Img';
import { getColor } from 'utils';

import Billboard from './Billboard';

import styles from './BlockProductGroup.module.scss';

const themeMap = {
    Duo: 'duo',
    'Duo Alt': 'duoAlt',
    'Duo Offset': 'duoOffset',
    'Duo Offset Alt': 'duoOffsetAlt',
    Triple: 'triple',
    'Triple Alt': 'tripleAlt',
    'Makers Reserve': 'makersReserve',
};

const BlockProductGroup = ({
    headline,
    desktopTheme,
    link,
    image,
    imageLarge,
    productBackgroundColor,
    productImage,
    productName,
}) => {
    useEffect(() => {
        if (!headline) {
            dispatchWarning(
                'No Headline provided. Headline required for render.'
            );
        }

        if (!image) {
            dispatchWarning(
                'No default image provided. Image required for render.'
            );
        }

        if (!imageLarge) {
            dispatchWarning(
                'No large image provided. Large image required for render.'
            );
        }

        if (!desktopTheme) {
            dispatchWarning(
                'No desktop theme provided. Desktop theme required for render.'
            );
        }

        if (!link) {
            dispatchWarning('No link provided. Link required for render.');
        }

        if (!productBackgroundColor) {
            dispatchWarning(
                'No product background color provided. Product background color required for render.'
            );
        }

        if (!productName) {
            dispatchWarning(
                'No product name provided. Product name required for render.'
            );
        }

        if (!productImage) {
            dispatchWarning(
                'No product image provided. Product image required for render.'
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    if (
        headline &&
        desktopTheme &&
        link &&
        image &&
        imageLarge &&
        productImage &&
        productBackgroundColor &&
        productName
    ) {
        return (
            <li>
                <Link.Entry entry={link}>
                    <section
                        className={cx(
                            styles.group,
                            styles[themeMap[desktopTheme]]
                        )}
                    >
                        {(themeMap[desktopTheme] === 'duo' ||
                            themeMap[desktopTheme] === 'duoAlt') && (
                            <div className={styles.callout}>
                                <Block
                                    image={image}
                                    imageLarge={imageLarge}
                                    richHeadline={headline}
                                    eyebrow={productName}
                                    theme="Square"
                                    contentTypeId={'blockCallout'}
                                />
                            </div>
                        )}
                        {(themeMap[desktopTheme] === 'duoOffset' ||
                            themeMap[desktopTheme] === 'duoOffsetAlt' ||
                            themeMap[desktopTheme] === 'makersReserve') && (
                            <Billboard
                                headline={
                                    themeMap[desktopTheme] !== 'makersReserve'
                                        ? headline
                                        : ''
                                }
                                eyebrow={productName}
                                image={image}
                                imageLarge={imageLarge}
                                isReversed={
                                    themeMap[desktopTheme] === 'duoOffsetAlt'
                                }
                                pseudoLink
                                link={
                                    themeMap[desktopTheme] === 'makersReserve'
                                        ? link
                                        : null
                                }
                            />
                        )}
                        {(themeMap[desktopTheme] === 'triple' ||
                            themeMap[desktopTheme] === 'tripleAlt') && (
                            <div className={styles.imageCard}>
                                <Img
                                    src={image.file.url}
                                    fallbackImageWidth={768}
                                    alt={image.description || ''}
                                    customSources={[
                                        {
                                            breakpoint: 1920,
                                            src: imageLarge.file.url,
                                            imageWidth: (1920 / 3) * 1.5,
                                        },
                                        {
                                            breakpoint: 1440,
                                            src: imageLarge.file.url,
                                            imageWidth: (1440 / 3) * 1.5,
                                        },
                                        {
                                            breakpoint: 1024,
                                            src: imageLarge.file.url,
                                            imageWidth: (1024 / 3) * 1.5,
                                        },
                                        {
                                            breakpoint: 768,
                                            src: imageLarge.file.url,
                                            imageWidth: (768 / 3) * 1.5,
                                        },
                                        {
                                            src: image.file.url,
                                            imageWidth: 768 * 1.5,
                                        },
                                    ]}
                                />
                            </div>
                        )}
                        {(themeMap[desktopTheme] === 'triple' ||
                            themeMap[desktopTheme] === 'tripleAlt' ||
                            themeMap[desktopTheme] === 'makersReserve') && (
                            <div className={styles.mainEyebrowWrapper}>
                                <div className={styles.mainEyebrowInner}>
                                    <TextEyebrow
                                        className={styles.mainEyebrow}
                                        eyebrow={productName}
                                        headline={headline}
                                        theme="displaySmall"
                                        icon="none"
                                    />
                                </div>
                            </div>
                        )}
                        <div className={styles.productCard}>
                            <CardProductCategory
                                backgroundColor={getColor(
                                    productBackgroundColor
                                )}
                                image={productImage}
                                title={productName}
                                theme={
                                    ((themeMap[desktopTheme] === 'duo' ||
                                        themeMap[desktopTheme] === 'duoAlt') &&
                                        'square') ||
                                    'trio'
                                }
                            />
                        </div>
                    </section>
                </Link.Entry>
            </li>
        );
    }

    return null;
};

BlockProductGroup.propTypes = {
    headline: PropTypes.string.isRequired,
    desktopTheme: PropTypes.string.isRequired,
    link: PropTypes.shape({
        contentTypeId: PropTypes.string.isRequired,
        slug: PropTypes.string,
    }).isRequired,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    productImage: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    productBackgroundColor: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
};

export default BlockProductGroup;
