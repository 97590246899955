import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { renderBlocks } from 'components/blocks/';

import { Head } from 'components/globals/Head';

const MakersReserve = ({ template: { blocks = [] }, ...rest }) => {
    return (
        <Fragment>
            <Head {...rest} />
            {renderBlocks(blocks)}
        </Fragment>
    );
};

MakersReserve.theme = 'makers-reserve';

MakersReserve.propTypes = {
    contentTypeId: PropTypes.string,
    metaDescription: PropTypes.string,
    slug: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.array.isRequired,
        contentTypeId: PropTypes.string.isRequired,
    }).isRequired,
    seoTitle: PropTypes.string.isRequired,
};

export default MakersReserve;
