import React from 'react';
import PropTypes from 'prop-types';

import Video from 'components/ui/Video';
import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';
import Link from 'components/ui/Link';

import styles from './BlockCommitmentFooter.module.scss';

const BlockCommitmentFooter = ({
    videoTextureMp4,
    videoTextureWebm,
    videoPoster,
    nextPage,
}) => {
    if (
        videoTextureMp4?.file?.url &&
        videoTextureWebm?.file?.url &&
        nextPage?.slug
    ) {
        return (
            <Link.Entry className={styles.linkWrapper} entry={nextPage}>
                <div className={styles.commitmentFooter}>
                    <div className={styles.aspect}>
                        <Video
                            key={nextPage.slug}
                            poster={videoPoster?.file?.url}
                            sources={[
                                videoTextureMp4.file.url,
                                videoTextureWebm.file.url,
                            ]}
                        />
                    </div>
                    <div className={styles.contentWrapper}>
                        <Text
                            className={styles.eyebrow}
                            baseTheme="labelSmall"
                            themes={{ large: 'labelLarge' }}
                        >
                            Next Section
                        </Text>

                        <Text
                            className={styles.nextPageTitle}
                            baseTheme="displayMedium"
                            themes={{ large: 'displayXLarge' }}
                        >
                            {nextPage.title}
                        </Text>

                        <div className={styles.iconWrapper}>
                            <SvgIcon iconType="arrowRight" />
                        </div>
                    </div>
                </div>
            </Link.Entry>
        );
    }

    return null;
};

BlockCommitmentFooter.propTypes = {
    nextPage: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    videoTextureMp4: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    videoTextureWebm: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    videoPoster: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
};

export default BlockCommitmentFooter;
