import React from 'react';
import PropTypes from 'prop-types';

import { renderBlocks } from 'components/blocks/';
import { Head } from 'components/globals/Head';

import styles from './VisitUs.module.scss';

const VisitUs = ({ template: { blocks }, ...rest }) => {
    return (
        <div className={styles.visitUsPage}>
            <Head {...rest} />

            {renderBlocks(blocks)}
        </div>
    );
};

VisitUs.propTypes = {
    blocks: PropTypes.array,
    contentTypeId: PropTypes.string,
    metaDescription: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string.isRequired,
                title: PropTypes.string,
            }).isRequired
        ),
        contentTypeId: PropTypes.string,
    }),
    seoTitle: PropTypes.string.isRequired,
};

VisitUs.defaultProps = {
    template: { blocks: [] },
};

export default VisitUs;
