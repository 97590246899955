import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

import { renderBlocks } from 'components/blocks/';
import CommitmentSubNav from './CommitmentSubNav';
import CommitmentHero from './CommitmentHero';
import BlockCommitmentFooter from 'components/blocks/BlockCommitmentFooter';

import {
    fetchCommitmentPages,
    useNormalizedContentfulResponse,
} from 'services/contentful';
import { Title } from 'components/globals/Title';

import styles from './Commitment.module.scss';

function getNextCommitmentPage(idx, pages) {
    if (idx < pages.length - 1) {
        return pages[idx + 1];
    }

    return pages[0];
}

function getPrevCommitmentPage(idx, pages) {
    if (idx > 0) {
        return pages[idx - 1];
    }

    return pages[pages.length - 1];
}

const Commitment = ({
    seoTitle,
    commitmentPages: commitmentPagesResponse,
    metaDescription,
    slug,
    template: {
        cta,
        blocks,
        videoTextureMp4,
        videoTextureWebm,
        videoPoster,
        subnavCalloutBlock,
        footerVideoTextureMp4,
        footerVideoTextureWebm,
        footerVideoPoster,
        richHeadline,
    },
}) => {
    let currentPage, nextPage, prevPage, currentPageIdx;

    const commitmentPages = useNormalizedContentfulResponse(
        commitmentPagesResponse
    )[0];

    if (commitmentPages?.pages?.length) {
        currentPage = commitmentPages.pages.find(page => {
            return `${page.slug}` === `stewardship/${slug}`;
        });

        currentPageIdx = commitmentPages?.pages.indexOf(currentPage);

        if (currentPage) {
            nextPage = getNextCommitmentPage(
                currentPageIdx,
                commitmentPages.pages
            );
            prevPage = getPrevCommitmentPage(
                currentPageIdx,
                commitmentPages.pages
            );
        }
    }

    return (
        <div className={styles.commitment}>
            <Title title={seoTitle} />
            <Head>
                {metaDescription && (
                    <meta
                        name="description"
                        key="description"
                        content={metaDescription}
                    />
                )}
            </Head>
            <CommitmentSubNav
                pages={commitmentPages.pages}
                currentPage={currentPage}
                idx={currentPageIdx}
            />
            <CommitmentHero
                callout={subnavCalloutBlock}
                currentPage={currentPage}
                nextPage={nextPage}
                prevPage={prevPage}
                videoTextureMp4={videoTextureMp4}
                videoTextureWebm={videoTextureWebm}
                videoPoster={videoPoster}
                cta={cta}
                richHeadline={richHeadline}
            />
            {renderBlocks(blocks)}
            <BlockCommitmentFooter
                videoTextureMp4={footerVideoTextureMp4}
                videoTextureWebm={footerVideoTextureWebm}
                videoPoster={footerVideoPoster}
                nextPage={nextPage}
                richHeadline={richHeadline}
            />
        </div>
    );
};

Commitment.getStaticTemplateProps = async ({ params }) => {
    const { page } = params;

    const commitmentPages = (await fetchCommitmentPages()) || [];

    return {
        commitmentPages,
        slug: page[page.length - 1],
    };
};

Commitment.propTypes = {
    blocks: PropTypes.array,
    commitmentPages: PropTypes.object,
    cta: PropTypes.any,
    currentPage: PropTypes.any,
    metaDescription: PropTypes.any,
    nextPage: PropTypes.any,
    prevPage: PropTypes.any,
    subnavCalloutBlock: PropTypes.any,
    template: PropTypes.shape({
        blocks: PropTypes.array,
        videoTextureMp4: PropTypes.any,
        videoTextureWebm: PropTypes.any,
        videoPoster: PropTypes.any,
        subnavCalloutBlock: PropTypes.any,
        footerVideoTextureMp4: PropTypes.any,
        footerVideoTextureWebm: PropTypes.any,
        footerVideoPoster: PropTypes.any,
        cta: PropTypes.any,
        richHeadline: PropTypes.any,
    }),
    seoTitle: PropTypes.any,
    slug: PropTypes.string,
};

Commitment.defaultProps = {
    prevPage: null,
    nextPage: null,
    currentPage: null,
    template: {
        blocks: [],
        videoTextureMp4: null,
        videoTextureWebm: null,
        subnavCalloutBlock: null,
        footerVideoTextureMp4: null,
        footerVideoTextureWebm: null,
        cta: null,
        richHeadline: null,
    },
};

export default Commitment;
