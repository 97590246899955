import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'components/ui/Link';
import TextEyebrow from 'components/ui/TextEyebrow';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import Img from 'components/ui/Img';

import styles from './Billboard.module.scss';

const Billboard = ({
    image,
    imageLarge,
    headline,
    eyebrow,
    isReversed,
    link,
    pseudoLink,
}) => {
    return (
        <div className={styles.billboard}>
            <Img
                src={image.file.url}
                fallbackImageWidth={768}
                alt={headline ? headline : eyebrow ? eyebrow : ''}
                customSources={[
                    {
                        breakpoint: 1920,
                        src: imageLarge.file.url,
                        imageWidth: 1920,
                    },
                    {
                        breakpoint: 1440,
                        src: imageLarge.file.url,
                        imageWidth: 1440,
                    },
                    {
                        breakpoint: 1024,
                        src: imageLarge.file.url,
                        imageWidth: 1024,
                    },
                    {
                        breakpoint: 768,
                        src: imageLarge.file.url,
                        imageWidth: 768,
                    },
                    {
                        src: image.file.url,
                        imageWidth: 767 * 1.5,
                    },
                ]}
            />
            {headline && (
                <div
                    className={cx(
                        styles.eyebrowWrapper,
                        isReversed && styles.reversed
                    )}
                >
                    <div className={styles.eyebrowInner}>
                        <TextEyebrow
                            className={styles.eyebrow}
                            eyebrow={eyebrow}
                            headline={headline}
                            theme="displaySmall"
                        />
                    </div>
                </div>
            )}
            {link && (
                <Link.Entry
                    className={styles.ctaWrapper}
                    entry={pseudoLink ? '' : link}
                >
                    <div className={styles.cta}>
                        <Text
                            baseTheme="headingMedium"
                            themes={{ large: 'headingLarge' }}
                        >
                            {eyebrow}
                        </Text>
                    </div>
                    <div className={styles.iconWrapper}>
                        <SvgIcon
                            iconType="circleArrow"
                            className={styles.icon}
                        />
                    </div>
                </Link.Entry>
            )}
        </div>
    );
};

Billboard.propTypes = {
    headline: PropTypes.string,
    eyebrow: PropTypes.string.isRequired,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    isReversed: PropTypes.bool,
    link: PropTypes.shape({
        contentTypeId: PropTypes.string.isRequired,
    }),
    pseudoLink: PropTypes.bool,
};

Billboard.defaultProps = {
    isReversed: false,
    headline: '',
    link: null,
    pseudoLink: false,
};

export default Billboard;
