import React from 'react';
import PropTypes from 'prop-types';
import {
    fetchPage,
    resolveEntryLink,
    fetchPrerenderPages,
    useNormalizedContentfulResponse,
} from 'services/contentful';
import { jsonLdScriptProps } from 'react-schemaorg';

import Head from 'next/head';

import DefaultLayout from 'components/layouts/Default';
import withSiteConfig from 'utils/withSiteConfig';

import jsonpack from 'jsonpack';

// Page Templates
import Default from 'components/pageTemplates/Default/';
import MakersReserve from 'components/pageTemplates/MakersReserve/';
import SharpnessStory from 'components/pageTemplates/SharpnessStory/';

import SimpleComponent from 'components/pageTemplates/SimpleComponent/';
import ProductCategory from 'components/pageTemplates/ProductCategory/';
import Recipes from 'components/pageTemplates/Recipes/';
import FAQ from 'components/pageTemplates/FAQ/';
import News from 'components/pageTemplates/News';
import Products from 'components/pageTemplates/Products';
import VisitUs from 'components/pageTemplates/VisitUs';
import Stewardship from 'components/pageTemplates/Stewardship';
import Commitment from 'components/pageTemplates/Commitment';
import IssueBriefs from 'components/pageTemplates/IssueBriefs';
import FoodServiceSubPage from 'components/pageTemplates/FoodServiceSubPage'; // remove after migration, replaced by FoodServicePage
import FoodServiceProductCategory from 'components/pageTemplates/foodservice/ProductCategory';
import FoodServicePage from 'components/pageTemplates/foodservice/Default';
import Restaurants from 'components/pageTemplates/Restaurants';

const templateMap = {
    pageTemplateDefault: Default,
    pageTemplateHardcoded: SimpleComponent,
    pageTemplateCategoryIndex: ProductCategory,
    pageTemplateRecipes: Recipes,
    pageTemplateFaq: FAQ,
    pageTemplateNews: News,
    pageTemplateProducts: Products,
    pageTemplateVisitUs: VisitUs,
    pageTemplateStewardship: Stewardship,
    pageTemplateCommitments: Commitment,
    pageTemplateRestaurants: Restaurants,
    pageTemplateIssueBrief: IssueBriefs,
    pageTemplateFoodServiceSubPage: FoodServiceSubPage,
    pageTemplateMakersReserve: MakersReserve,
    pageTemplateSharpnessStory: SharpnessStory,
    pageTemplateFoodServiceCategoryIndex: FoodServiceProductCategory,
    pageTemplateFoodServicePage: FoodServicePage,

    default: () => {
        return null;
    },
};

const Page = ({ pageRequest, siteRequest, ...rest }) => {
    const page = useNormalizedContentfulResponse(pageRequest)[0];
    const siteConfig = useNormalizedContentfulResponse(siteRequest)[0];
    const seoStructuredData = page?.seoStructuredData;

    const Template =
        templateMap[page?.template?.contentTypeId] || templateMap['default'];

    return (
        <>
            <Head>
                {page?.noindex && (
                    <>
                        <meta name="robots" content="noindex" />
                        <meta name="googlebot" content="noindex" />
                    </>
                )}
                {seoStructuredData && (
                    <script {...jsonLdScriptProps(page.seoStructuredData)} />
                )}
            </Head>
            <Template {...page} {...rest} siteConfig={siteConfig} />
        </>
    );
};

Page.propTypes = {
    pageRequest: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    siteRequest: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export const getStaticProps = withSiteConfig(async ctx => {
    const pageParam = ctx.params?.page || [''];
    const pageRoute = pageParam.join('/');
    const pageRequest = await fetchPage(pageRoute === '' ? '/' : pageRoute);

    if (!pageRequest || pageRequest.total === 0) {
        return { notFound: true };
    }

    const page = pageRequest.items[0];

    const template = resolveEntryLink(page?.fields?.template, pageRequest);

    if (!template) {
        return { notFound: true };
    }

    const templateContentType = template?.sys?.contentType?.sys?.id;

    const templateInitialProps = templateMap[templateContentType]
        ?.getStaticTemplateProps
        ? await templateMap[templateContentType].getStaticTemplateProps(
              ctx,
              pageRequest
          )
        : null;

    return {
        props: {
            theme:
                templateMap[templateContentType]?.theme ||
                template.fields?.theme ||
                'default',
            pageRequest: ctx.req ? jsonpack.pack(pageRequest) : pageRequest,
            ...(templateContentType ? { templateContentType } : {}),
            ...templateInitialProps, // this may cause collisions with top level page props if not careful
        },
    };
});

export async function getStaticPaths() {
    const pages = await fetchPrerenderPages();

    return {
        paths: pages.items
            .filter(
                page =>
                    typeof page.fields.slug !== 'undefined' &&
                    page.fields.slug !== '' &&
                    page.fields.slug !== '/' &&
                    typeof page.fields.slug !== 'undefined' &&
                    !/^recipes/g.test(page.fields.slug) &&
                    !/^foodservice/g.test(page.fields.slug)
            )
            .map(page => {
                return {
                    params: { page: page.fields?.slug?.split('/') || [] },
                };
            }),
        fallback: 'blocking',
    };
}

Page.getLayout = initialProps => {
    const Layout =
        templateMap[initialProps?.templateContentType]?.Layout || DefaultLayout;

    return Layout;
};

export default Page;
