import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Video from 'components/ui/Video';
import Text from 'components/ui/Text';
import ButtonCircle from 'components/ui/ButtonCircle';

import Block from 'components/blocks/';

import CTA from 'components/ui/CTA';
import Stagger from 'components/ui/Stagger';

import styles from './CommitmentHero.module.scss';

const CommitmentHero = ({
    videoTextureMp4,
    videoTextureWebm,
    videoPoster,
    callout,
    currentPage,
    nextPage,
    prevPage,
    cta,
    richHeadline,
}) => {
    if (
        currentPage &&
        nextPage &&
        prevPage &&
        videoTextureMp4 &&
        videoTextureWebm
    ) {
        return (
            <div className={styles.commitmentSubnav}>
                <Stagger className={styles.aspect}>
                    <Video
                        key={currentPage.slug}
                        poster={videoPoster?.file?.url}
                        sources={[
                            videoTextureMp4.file.url,
                            videoTextureWebm.file.url,
                        ]}
                    />
                    {prevPage && (
                        <div className={cx(styles.navArrow, styles.left)}>
                            <ButtonCircle
                                href={`/${prevPage.slug}`}
                                theme="chevronLeftWhite"
                            />
                        </div>
                    )}
                    {nextPage && (
                        <div className={cx(styles.navArrow, styles.right)}>
                            <ButtonCircle
                                href={`/${nextPage.slug}`}
                                theme="chevronRightWhite"
                            />
                        </div>
                    )}
                    <div className={styles.contentWrapper}>
                        <Stagger.Child
                            order={0}
                            className={styles.richHeadline}
                        >
                            {richHeadline && (
                                <Text
                                    as="h2"
                                    baseTheme="displayMedium"
                                    themes={{ large: 'displayXLarge' }}
                                >
                                    {richHeadline.content.map((line, i) => {
                                        if (line.content[0].value === '') {
                                            return null;
                                        }
                                        return (
                                            <span
                                                className={styles.line}
                                                key={i}
                                            >
                                                {line.content[0].value}
                                            </span>
                                        );
                                    })}
                                </Text>
                            )}
                            {!richHeadline && (
                                <Text
                                    baseTheme="displayMedium"
                                    themes={{ large: 'displayXLarge' }}
                                >
                                    {currentPage.title}
                                </Text>
                            )}
                        </Stagger.Child>
                        <Stagger.ArrowRight
                            order={1}
                            className={styles.arrowIcon}
                        />
                    </div>
                    {cta && (
                        <div className={styles.ctaWrapper}>
                            <Stagger.Child order={2}>
                                <CTA
                                    className={styles.cta}
                                    theme="filledOrange"
                                    cta={cta}
                                >
                                    {cta.text}
                                </CTA>
                            </Stagger.Child>
                        </div>
                    )}
                </Stagger>
                {callout && (
                    <div className={styles.calloutWrapper}>
                        <Block {...callout} contentTypeId="blockCallout" />
                    </div>
                )}
            </div>
        );
    }

    return null;
};

CommitmentHero.propTypes = {
    videoTextureMp4: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    videoTextureWebm: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    videoPoster: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    callout: PropTypes.shape({
        headline: PropTypes.string.isRequired,
        copy: PropTypes.string.isRequired,
    }),
    cta: PropTypes.object,
    currentPage: PropTypes.shape({
        title: PropTypes.string.isRequired,
        idx: PropTypes.number,
        slug: PropTypes.string.isRequired,
    }),
    prevPage: PropTypes.shape({
        slug: PropTypes.string.isRequired,
    }),
    nextPage: PropTypes.shape({
        slug: PropTypes.string.isRequired,
    }),
    richHeadline: PropTypes.object,
};

CommitmentHero.defaultProps = {
    callout: null,
    currentPage: null,
    nextPage: null,
    prevPage: null,
};

export default CommitmentHero;
