import React from 'react';
import PropTypes from 'prop-types';

import Block, { renderBlocks } from 'components/blocks/';

import { Head } from 'components/globals/Head';

import HeroCTA from './HeroCTA';
import CardFeatured from './CardFeatured';
import JoinOurRestaurantsCTA from './JoinOurRestaurantsCTA';

import { cleanupRestaurantData } from './utils';

import styles from './Restaurants.module.scss';

const Restaurants = ({
    template: { hero, featuredRestaurants, topBlocks },
    ...rest
}) => {
    const ctaContent = {
        headline: 'join our restaurant map',
        body: 'You love creating delicious dishes using Tillamook products—and we want to tell the world. It’s easy to get your restaurant added to the Restaurant Finder.',
        cta: { text: 'apply now', url: '/where-to-eat/apply' },
    };

    return (
        <>
            <Head {...rest} />
            <div className={styles.root}>
                <Block
                    {...hero}
                    className={styles.topHero}
                    contentTypeId={'blockFullWidthMedia'}
                    firstBlock={true}
                />
                <HeroCTA />
                {renderBlocks(topBlocks)}
                <div className={styles.cards}>
                    {cleanupRestaurantData(featuredRestaurants).map(
                        (item, i) => (
                            <CardFeatured {...item} key={i} />
                        )
                    )}
                </div>
                <JoinOurRestaurantsCTA {...ctaContent} />
            </div>
        </>
    );
};

Restaurants.propTypes = {
    template: PropTypes.shape({
        hero: PropTypes.object,
        featuredRestaurants: PropTypes.array,
        topBlocks: PropTypes.array,
    }),
};

export default Restaurants;
