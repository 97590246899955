import React, { useState, useMemo, useCallback } from 'react';

import Fuse from 'fuse.js';
import PropTypes from 'prop-types';

import useQueryState from 'hooks/useQueryState';

import FilterBody from 'components/ui/FilterBody/';
import CardStoryGrid from 'components/cards/CardStoryGrid';
import CardNoResults from 'components/cards/CardNoResults';

import styles from './NewsList.module.scss';

const NewsList = ({ articles, newsAttributes }) => {
    const [state, setState] = useQueryState();
    const [search, setSearch] = useState('');

    const searcher = useMemo(() => {
        return new Fuse(articles, { keys: ['title', 'keywords'] });
    }, [articles]);

    // Run though all articles to build a list of available filters
    const filters = useMemo(() => {
        return articles.reduce((o, article) => {
            Object.entries(article.attributes || {}).forEach(([key, value]) => {
                const tagAttribute = newsAttributes[key] || false;

                if (!tagAttribute) {
                    return;
                }

                const options = tagAttribute.options.filter(filter => {
                    if (tagAttribute.type === 'multiple') {
                        return [...value].includes(`${filter.id}`);
                    } else {
                        return `${filter.id}` === `${value}`;
                    }
                });

                if (!options.length === 0) {
                    console.warn(
                        `Tag attribute value of \`${value}\` not found attribute in ${key}: ${article.title}`
                    );
                    return;
                }

                const optionObj = options.reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});

                o[key] = {
                    ...tagAttribute,
                    options: {
                        ...((o[key] && o[key].options) || {}),
                        ...optionObj,
                    },
                };
            });

            return o;
        }, {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articles]);

    const [filtered, setFiltered] = useState(state);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearch = useCallback(evt => {
        setSearch(evt.target.value);
    });

    const newsSearched =
        search.length === 0
            ? articles
            : searcher.search(search).map(article => article.item);

    const newsFiltered = newsSearched.filter(article => {
        if (Object.keys(state).length === 0) {
            return true;
        }
        return Object.keys(state).every(attrKey => {
            if (!article.attributes) {
                return false;
            }
            const option = newsAttributes[attrKey]?.options.find(
                option => option.slug == state[attrKey]
            );
            if (Array.isArray(article.attributes[attrKey])) {
                return article.attributes[attrKey].includes(`${option?.id}`);
            }
            return `${article.attributes[attrKey]}` === `${option?.id}`;
        });
    });

    const handleChangeFilter = key => event => {
        const state = {
            ...filtered,
            [key]: event.currentTarget.value,
        };
        if (event.currentTarget.value === '') {
            delete state[key];
        }
        setState(state);
        setFiltered(state);
    };

    const isNoResults = newsFiltered.length === 0;

    return (
        <section className={styles.root}>
            <FilterBody onSearch={handleSearch} theme="block">
                {Object.keys(filters).map(key => {
                    return Object.keys(filters[key].options).length >= 1 ? (
                        <FilterBody.Select
                            key={`${key}-select`}
                            filters={filters}
                            filtered={filtered}
                            keyValue={key}
                            label={filters[key].label}
                            value={filtered[key] || ''}
                            onChange={handleChangeFilter(key)}
                        />
                    ) : null;
                })}
            </FilterBody>
            <ul className={styles.inner}>
                {newsFiltered.map(news => {
                    if (!news.slug) {
                        return null;
                    }

                    // Fix for main image showing up instead of card image on the card
                    news.image = news.cardImage || news.image;

                    return (
                        <li className={styles.listItem} key={news.id}>
                            <CardStoryGrid {...news} />
                        </li>
                    );
                })}
                <li key="end-card" className={styles.listItem}>
                    {isNoResults && <CardNoResults search={search} />}
                </li>
            </ul>
        </section>
    );
};

NewsList.propTypes = {
    articles: PropTypes.array,
    theme: PropTypes.string.isRequired,
    headline: PropTypes.string,
    eyebrow: PropTypes.string,
    useCategorySort: PropTypes.bool,
    newsAttributes: PropTypes.object.isRequired,
};

NewsList.defaultProps = {
    theme: 'default',
};

export default NewsList;
