import React from 'react';

import PropTypes from 'prop-types';
import { renderBlocks } from 'components/blocks/';
import { HeaderBottom } from 'components/globals/Header/Header';
import SubNav from 'components/pageTemplates/foodservice/SubNav';

const FoodServiceContactLayout = ({ template: { blocks = [] } }) => {
    return (
        <>
            <HeaderBottom.Source>
                <SubNav
                    category={{
                        title: 'Contact',
                        slug: 'foodservice/contact',
                    }}
                    toggleMode
                />
            </HeaderBottom.Source>
            {renderBlocks(blocks)}
        </>
    );
};

FoodServiceContactLayout.propTypes = {
    blocks: PropTypes.array,
    contentTypeId: PropTypes.string,
    metaDescription: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string.isRequired,
                title: PropTypes.string,
            })
        ),
        contentTypeId: PropTypes.string,
    }),
    seoTitle: PropTypes.string.isRequired,
};

export default FoodServiceContactLayout;
