import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Head } from 'components/globals/Head';
import None from 'components/layouts/None';
import SharpnessStory from 'components/overlays/SharpnessStory';

const componentMap = {
    SharpnessStory: SharpnessStory,
    Default: () => null,
};

const SimpleComponent = props => {
    const Component =
        componentMap[props.template.component] || componentMap.Default;
    return (
        <Fragment>
            <Head {...props} />
            <Component {...props} />
        </Fragment>
    );
};

SimpleComponent.propTypes = {
    title: PropTypes.string.isRequired,
    slug: PropTypes.string,
    template: PropTypes.shape({
        contentTypeId: PropTypes.string.isRequired,
        component: PropTypes.string.isRequired,
    }).isRequired,
    contentTypeId: PropTypes.string,
};

SimpleComponent.Layout = None;

export default SimpleComponent;
