import React from 'react';
import PropTypes from 'prop-types';

import { Head } from 'components/globals/Head';
import Block from 'components/blocks/';
import CardCommitment from 'components/cards/CardCommitment';
import TextEyebrow from 'components/ui/TextEyebrow';

import styles from './IssueBriefs.module.scss';

const IssueBriefs = ({
    template: { blocks, commitmentCards, eyebrow, eyebrowHeadline },
    ...rest
}) => {
    return (
        <div className={styles.issueBriefsPage}>
            <Head {...rest} />
            {blocks.map((blockProps, i) => (
                <Block key={`issueBriefs-block-${i}`} {...blockProps} />
            ))}
            {commitmentCards && (
                <article className={styles.commitmentCardsFlex}>
                    <TextEyebrow
                        eyebrow={eyebrow}
                        headline={eyebrowHeadline}
                        className={styles.textEyebrow}
                        theme="display"
                        headlineAs="h1"
                    />
                    <div className={styles.commitmentCardsWrap}>
                        <ul className={styles.commitmentCards}>
                            {commitmentCards?.pages.map((card, i) => (
                                <li className={styles.card} key={i}>
                                    <CardCommitment {...card} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </article>
            )}
        </div>
    );
};

IssueBriefs.propTypes = {
    blocks: PropTypes.array,
    commitmentCards: PropTypes.shape({
        pages: PropTypes.array,
    }),
    metaDescription: PropTypes.string,
    pages: PropTypes.array,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string.isRequired,
                title: PropTypes.string,
            }).isRequired
        ),
        commitmentCards: PropTypes.shape({
            pages: PropTypes.arrayOf(
                PropTypes.shape({
                    image: PropTypes.object.isRequired,
                    imageLarge: PropTypes.object.isRequired,
                    description: PropTypes.string.isRequired,
                    page: PropTypes.object.isRequired,
                }).isRequired
            ),
        }),
        contentTypeId: PropTypes.string,
        eyebrow: PropTypes.string,
        eyebrowHeadline: PropTypes.string,
    }),
    seoTitle: PropTypes.string.isRequired,
};

IssueBriefs.defaultProps = {
    template: {
        blocks: [],
        commitmentCards: { pages: [] },
    },
};

export default IssueBriefs;
