import React from 'react';
import PropTypes from 'prop-types';

import { renderBlocks } from 'components/blocks/';

import styles from './Landing.module.scss';

const Landing = ({ template: { blocks } }) => {
    return (
        <>
            <div className={styles.outerWrapper}>
                <div className={styles.innerWrapper}>
                    {renderBlocks(blocks)}
                </div>
            </div>
        </>
    );
};

Landing.propTypes = {
    blocks: PropTypes.array,
    contentTypeId: PropTypes.string,
    description: PropTypes.string,
    metaDescription: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string,
                title: PropTypes.string,
            })
        ),
        contentTypeId: PropTypes.string,
    }),
    seoTitle: PropTypes.any,
};

Landing.defaultProps = {
    template: { blocks: [] },
};

export default Landing;
