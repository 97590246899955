import React, { useState } from 'react';

import cx from 'classnames';

import PropTypes from 'prop-types';
import { renderBlocks } from 'components/blocks/';
import { HeaderBottom } from 'components/globals/Header/Header';
import SubNav from 'components/pageTemplates/foodservice/SubNav';
import RichText from 'components/ui/RichText';
import { BLOCKS } from '@contentful/rich-text-types';
import Text from 'components/ui/Text';
import Img from 'components/ui/Img';

import UltraBoldHeading from 'components/ui/UltraBoldHeading';

import styles from './FoodServiceLanding.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodyMedium"
                    themes={{
                        medium: 'headingSmall',
                        large: 'headingMedium',
                    }}
                    className={styles.body}
                >
                    {children}
                </Text>
            );
        },
    },
};

const Default = ({ template: { blocks = [] } }) => {
    const [transition, setTransition] = useState('entered');

    return (
        <>
            <HeaderBottom.Source>
                <SubNav transition={transition} setTransition={setTransition} />
            </HeaderBottom.Source>
            <div className={cx(styles.heading, styles[transition])}>
                <UltraBoldHeading
                    baseTheme={'displayMedium'}
                    themes={{
                        medium: 'displayXLarge',
                        large: 'displayXXLarge',
                    }}
                    icon={'morningStar'}
                    headline={{
                        data: {},
                        content: [
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'Foodservice',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: 'paragraph',
                            },
                        ],
                        nodeType: 'document',
                    }}
                />
                <RichText
                    overrides={richTextOverrides}
                    richText={{
                        data: {},
                        content: [
                            {
                                data: {},
                                content: [
                                    {
                                        data: {},
                                        marks: [],
                                        value: 'Bring your menu to life with delicious dairy \n that brings goodness to everyday dining.',
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: 'paragraph',
                            },
                        ],
                        nodeType: 'document',
                    }}
                />
            </div>
            <div className={cx(styles.hero, styles[transition])}>
                <Img
                    className={styles.image}
                    src={
                        'https://images.ctfassets.net/j8tkpy1gjhi5/6DK5WKhBCatWKT9ZTHWgyh/f0b5f2be3f5d5668fd90afab3f1ed08d/Creamery_Kitchen.png'
                    }
                    width={4320}
                    height={2160}
                    alt={'Kitchen'}
                    priority
                />
            </div>
            {renderBlocks(blocks)}
        </>
    );
};

Default.propTypes = {
    blocks: PropTypes.array,
    contentTypeId: PropTypes.string,
    metaDescription: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string.isRequired,
                title: PropTypes.string,
            })
        ),
        contentTypeId: PropTypes.string,
    }),
    seoTitle: PropTypes.string.isRequired,
};

export default Default;
