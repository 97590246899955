import React from 'react';
import PropTypes from 'prop-types';

import Block, { renderBlocks } from 'components/blocks/';
import BlockProductGroup from 'components/blocks/BlockProductGroup';
import BlockProductNav from 'components/blocks/BlockProductNav';

import { Head } from 'components/globals/Head';

import styles from './Products.module.scss';

const Products = ({ template, ...rest }) => {
    return (
        <div className={styles.productsPage}>
            <Head {...rest} />
            {template?.gridEyebrow && template?.gridHeadline && (
                <Block
                    richHeadline={{
                        nodeType: 'document',
                        content: [
                            {
                                nodeType: 'paragraph',
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: `${template.gridHeadline}`,
                                        marks: [],
                                    },
                                ],
                            },
                        ],
                    }}
                    eyebrow={template.gridEyebrow}
                    theme="Banner"
                    topLevel={true}
                    colorTheme="Cream Background"
                    contentTypeId="blockCallout"
                />
            )}
            {template?.productNavigation && (
                <BlockProductNav {...template.productNavigation} />
            )}
            {template?.grid && (
                <ul className={styles.productGrid}>
                    {template.grid.map((group, idx) => (
                        <BlockProductGroup key={idx} {...group} />
                    ))}
                </ul>
            )}
            {template?.blocks && renderBlocks(template.blocks)}
            {template?.whereToBuy && (
                <Block
                    topLevel={true}
                    {...template.whereToBuy}
                    contentTypeId="blockWhereToBuy"
                />
            )}
        </div>
    );
};

Products.propTypes = {
    blocks: PropTypes.array,
    grid: PropTypes.array,
    gridEyebrow: PropTypes.string,
    gridHeadline: PropTypes.string,
    metaDescription: PropTypes.string,
    productNavigation: PropTypes.object,
    template: PropTypes.shape({
        blocks: PropTypes.array,
        grid: PropTypes.array,
        gridEyebrow: PropTypes.string,
        gridHeadline: PropTypes.string,
        productNavigation: PropTypes.object,
        whereToBuy: PropTypes.object,
    }),
    seoTitle: PropTypes.string,
};

Products.defaultProps = {
    blocks: [],
    grid: [],
    gridEyebrow: '',
    template: {
        blocks: [],
        grid: [],
        gridEyebrow: '',
        gridHeadline: '',
        productNavigation: {},
        whereToBuy: {},
    },
};

export default Products;
