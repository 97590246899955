import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { HeaderBottom } from 'components/globals/Header/Header';

import Block, { renderBlocks } from 'components/blocks/';
import BlockCategoryDetailIntro from 'components/blocks/BlockCategoryDetailIntro';
import SubNav from '../SubNav';

import CardProductCategory from 'components/cards/CardProductCategory';
import Button from 'components/ui/Button';

import { Head } from 'components/globals/Head';

import styles from './ProductCategory.module.scss';

import Link from 'components/ui/Link';
import { getColor } from 'utils';

const ProductCategory = ({ template, ...rest }) => {
    return (
        <div className={styles.productCategoryPage}>
            <Head {...rest} />
            {template?.categoryDetailIntro && (
                <BlockCategoryDetailIntro {...template.categoryDetailIntro} />
            )}
            {template?.productGridEyebrow &&
                template?.productGridRichTextHeadline && (
                    <div className={styles.callout}>
                        <Block
                            richHeadline={template.productGridRichTextHeadline}
                            eyebrow={template.productGridEyebrow}
                            theme="Banner"
                            colorTheme="Blue Background"
                            hasNotch
                            contentTypeId="blockCallout"
                            buttonComponentSlot={
                                template.productGridCtaCard?.cta?.link &&
                                template.productGridCtaCard?.cta?.text && (
                                    <Button
                                        theme="outlinedCream"
                                        entry={
                                            template.productGridCtaCard?.cta
                                                ?.link
                                        }
                                    >
                                        {template.productGridCtaCard?.cta?.text}
                                    </Button>
                                )
                            }
                        />
                    </div>
                )}
            <HeaderBottom.Source>
                <SubNav
                    category={{
                        title: template.category.title,
                        slug: template.category.slug,
                        navLabel: template.category.navLabel,
                    }}
                    toggleMode
                />
            </HeaderBottom.Source>
            {template?.blocksBeforeProducts?.map((blockProps, i) => (
                <Block
                    key={`category-blockBeforeProducts-${i}`}
                    {...blockProps}
                />
            ))}
            {template?.productGrid && (
                <article
                    className={cx(styles.productGridFlex, {
                        [styles.flushBottom]: template?.productGridFlushBottom,
                    })}
                >
                    <ul className={styles.productGrid}>
                        {template.productGrid.map((card, idx) => {
                            return !card.link?.contentTypeId ? (
                                'DRAFT'
                            ) : (
                                <li
                                    className={cx(styles.gridCard, {
                                        [styles.fullWidth]: card.fullWidth,
                                    })}
                                    key={idx}
                                >
                                    <Link.Entry entry={card.link}>
                                        <CardProductCategory
                                            title={card.productTitle}
                                            image={card.productImage}
                                            theme={'detail'}
                                            backgroundColor={getColor(
                                                card.backgroundColor
                                            )}
                                            fullWidth={card.fullWidth}
                                        />
                                    </Link.Entry>
                                </li>
                            );
                        })}
                    </ul>
                </article>
            )}
            {template?.blocks && renderBlocks(template?.blocks)}
        </div>
    );
};

ProductCategory.propTypes = {
    metaDescription: PropTypes.string,
    slug: PropTypes.string.isRequired,
    template: PropTypes.object,
    seoTitle: PropTypes.string.isRequired,
};

ProductCategory.defaultProps = {
    template: {
        blocks: [],
        productGridFlushBottom: true,
    },
};

export default ProductCategory;
