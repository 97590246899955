import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';
import { renderBlocks } from 'components/blocks/';
import { HeaderBottom } from 'components/globals/Header/Header';
import SubNav from 'components/pageTemplates/foodservice/SubNav';

const Default = ({ template: { blocks = [] } }) => {
    function isInView(element) {
        const { top, left, bottom, right } = element.getBoundingClientRect();

        return (
            top >= 0 &&
            left >= 0 &&
            bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
            right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const handleLoad = () => {
        if (window.location.hash) {
            const id = window.location.hash.replace(/#/g, '');
            const element = document.getElementById(id);

            if (element && !isInView(element)) {
                element.scrollIntoView();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('load', handleLoad);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <HeaderBottom.Source>
                <SubNav toggleMode />
            </HeaderBottom.Source>
            {renderBlocks(blocks)}
        </Fragment>
    );
};

Default.propTypes = {
    blocks: PropTypes.array,
    contentTypeId: PropTypes.string,
    metaDescription: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string.isRequired,
                title: PropTypes.string,
            })
        ),
        contentTypeId: PropTypes.string,
    }),
    seoTitle: PropTypes.string.isRequired,
};

export default Default;
