import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Link from 'components/ui/Link';
import Img from 'components/ui/Img';

import { getReadableMonth } from 'utils';

import styles from './CardStoryGrid.module.scss';

const CardStoryGrid = ({ title, category, slug, image, date }) => {
    const formattedDate = date ? new Date(date) : null;

    return (
        <article className={styles.root}>
            <Link className={styles.link} href={`/news/${slug}`}>
                <figure className={styles.aspectBox}>
                    {image?.file?.url && (
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            alt={image.description || ''}
                            width={image?.file.details?.image?.width}
                            height={image?.file.details?.image?.height}
                            customSources={[
                                {
                                    breakpoint: 1920,
                                    src: image.file.url,
                                    imageWidth: 1920 * 0.5,
                                },
                                {
                                    breakpoint: 1440,
                                    src: image.file.url,
                                    imageWidth: 1440 * 0.5,
                                },
                                {
                                    breakpoint: 1024,
                                    src: image.file.url,
                                    imageWidth: 1024 * 0.5,
                                },
                                {
                                    breakpoint: 768,
                                    src: image.file.url,
                                    imageWidth: 768 * 0.75,
                                },
                                {
                                    src: image.file.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
                </figure>
                <figcaption
                    className={cx(styles.textBlock, date && styles.date)}
                >
                    {category && (
                        <Text
                            baseTheme="labelSmall"
                            themes={{ medium: 'labelLarge' }}
                            className={styles.storySubHeadline}
                            as="h3"
                        >
                            {category}
                        </Text>
                    )}
                    {formattedDate && (
                        <Text
                            baseTheme="bodySmall"
                            themes={{ medium: 'bodyMedium' }}
                            className={styles.storyDate}
                            as="h3"
                        >
                            {`${getReadableMonth(
                                formattedDate.getUTCMonth()
                            )} ${formattedDate.getUTCDate()}, ${formattedDate.getUTCFullYear()}`}
                        </Text>
                    )}
                    <div className={styles.storyHeadlineWrapper}>
                        <Text
                            baseTheme="headingSmall"
                            themes={{ medium: 'headingMedium' }}
                            className={styles.storyHeadline}
                            as="h1"
                        >
                            {title}
                        </Text>
                    </div>
                </figcaption>
            </Link>
        </article>
    );
};

CardStoryGrid.propTypes = {
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    category: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
};

export default CardStoryGrid;
