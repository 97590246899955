import React from 'react';
import PropTypes from 'prop-types';

import Block, { renderBlocks } from 'components/blocks/';

const CampaignCreamCheese = ({ template: { blocks } }) => {
    const fullWidthMediaBlock = blocks.filter(
        block => block.contentTypeId === 'blockFullWidthMedia'
    );

    const heroTwoColumnBlock = blocks.filter(
        block => block.contentTypeId === 'blockHeroTwoColumn'
    );

    const restBlocks = blocks.filter(
        block =>
            block.contentTypeId !== 'blockFullWidthMedia' &&
            block.contentTypeId !== 'blockHeroTwoColumn'
    );

    return (
        <>
            {fullWidthMediaBlock && (
                <Block
                    contentTypeId="blockFullWidthMedia"
                    {...fullWidthMediaBlock[0]}
                />
            )}
            {heroTwoColumnBlock && (
                <Block
                    contentTypeId="blockHeroTwoColumn"
                    {...heroTwoColumnBlock[0]}
                />
            )}
            {renderBlocks(restBlocks)}
        </>
    );
};

CampaignCreamCheese.propTypes = {
    blocks: PropTypes.array,
    contentTypeId: PropTypes.string,
    description: PropTypes.string,
    metaDescription: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string,
                title: PropTypes.string,
            })
        ),
        contentTypeId: PropTypes.string,
    }),
    seoTitle: PropTypes.any,
};

CampaignCreamCheese.defaultProps = {
    template: { blocks: [] },
};

export default CampaignCreamCheese;
