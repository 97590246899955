import React, { Fragment } from 'react';
import { Head } from 'components/globals/Head';

import PropTypes from 'prop-types';

import DefaultLayout from './layouts/Default';
import ContactLayout from './layouts/Contact';
import LandingLayout from './layouts/Landing';
import FoodServiceLandingLayout from './layouts/FoodServiceLanding';
import FoodServiceContactLayout from './layouts/FoodServiceContact';

import CampaignCreamCheeseLayout from './layouts/CampaignCreamCheese';

const layouts = {
    'Campaign: Cream Cheese': CampaignCreamCheeseLayout,
    Landing: LandingLayout,
    Contact: ContactLayout,
    Default: DefaultLayout,
    'Food Service Landing': FoodServiceLandingLayout,
    'Food Service Contact': FoodServiceContactLayout,
};

const Default = props => {
    const LayoutComponent = layouts[props.template?.layout] || layouts.Default;
    const { seoTitle, metaDescription, shareImage } = props;
    return (
        <Fragment>
            <Head
                seoTitle={seoTitle}
                shareTitle={seoTitle}
                metaDescription={metaDescription}
                shareDescription={metaDescription}
                shareImage={shareImage}
            />
            <LayoutComponent {...props} />
        </Fragment>
    );
};

Default.propTypes = {
    contentTypeId: PropTypes.string,
    metaDescription: PropTypes.string,
    slug: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.array.isRequired,
        contentTypeId: PropTypes.string.isRequired,
        layout: PropTypes.string.isRequired,
    }).isRequired,
    seoTitle: PropTypes.string.isRequired,
    shareImage: PropTypes.object,
};

export default Default;
