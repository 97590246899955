import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import Text from 'components/ui/Text';
import Img from 'components/ui/Img';
import ButtonCircle from 'components/ui/ButtonCircle';
import Link from 'components/ui/Link';

import styles from './CardFeatured.module.scss';

const CardFeatured = ({
    title,
    featuredImage,
    city,
    state,
    id,
    streetAddress,
}) => {
    const address =
        streetAddress && city && state
            ? `input=${streetAddress}, ${city}, ${state}`
            : ``;

    return (
        <Link
            href={`/where-to-eat/map?id=${id}&${address}&type=place`}
            className={styles.root}
        >
            <div className={styles.imageContainer}>
                <Img
                    className={styles.image}
                    src={featuredImage?.file?.url}
                    fallbackImageWidth={768}
                    alt={featuredImage?.description || ''}
                    customSources={[
                        {
                            breakpoint: 768,
                            src: featuredImage?.file?.url,
                            imageWidth: 1000,
                        },
                        {
                            src: featuredImage?.file?.url,
                            imageWidth: 768,
                        },
                    ]}
                />
            </div>
            <div className={styles.textContainer}>
                <div className={styles.text}>
                    <Text
                        baseTheme="displayXSmall"
                        className={styles.title}
                        fixWidows
                    >
                        {title}
                    </Text>
                    <Text
                        baseTheme="labelMedium"
                        themes={{ large: 'labelLarge' }}
                        className={styles.address}
                    >
                        {`${city}, ${state}`}
                    </Text>
                </div>
                <ButtonCircle
                    className={styles.circleButton}
                    theme="arrowRightRoundedBlue"
                />
            </div>
        </Link>
    );
};
CardFeatured.propTypes = {
    city: PropTypes.string,
    featuredImage: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
    id: PropTypes.string,
    state: PropTypes.string,
    streetAddress: PropTypes.string,
    title: PropTypes.string,
};

export default CardFeatured;
