import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

import { renderBlocks } from 'components/blocks/';
import RecipesList from './RecipesList';

import { useNormalizedContentfulResponse } from 'services/contentful';

const Recipes = ({
    recipes: recipesResponse,
    template: { blocks },
    ...rest
}) => {
    const siteConfig = useNormalizedContentfulResponse(
        rest.siteConfigRequest
    )[0];

    const recipes = useNormalizedContentfulResponse(recipesResponse);
    const recipeAttributes = siteConfig?.contentTypeAttributes?.recipe || {};

    return (
        <div>
            <Head {...rest} />
            {renderBlocks(blocks)}
            {recipes?.length && (
                <RecipesList
                    recipes={recipes}
                    recipeAttributes={recipeAttributes}
                />
            )}
        </div>
    );
};

Recipes.propTypes = {
    blocks: PropTypes.array,
    contentTypeId: PropTypes.string,
    metaDescription: PropTypes.string,
    recipes: PropTypes.object.isRequired,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string.isRequired,
                title: PropTypes.string,
            }).isRequired
        ),
        contentTypeId: PropTypes.string,
    }),
    seoTitle: PropTypes.string.isRequired,
    contentTypeAttributes: PropTypes.object.isRequired,
};

Recipes.defaultProps = {
    template: { blocks: [] },
    recipes: [],
    contentTypeAttributes: {},
};

export default Recipes;
