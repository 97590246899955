import React from 'react';
import PropTypes from 'prop-types';

const None = ({ Component, pageProps, user }) => {
    return <Component {...pageProps} user={user} />;
};

None.getInitialProps = async ({ Component, ctx }) => {
    return {
        ...(Component.getInitialProps
            ? await Component.getInitialProps(ctx)
            : {}),
    };
};

None.propTypes = {
    Component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
    pageProps: PropTypes.object.isRequired,
    user: PropTypes.object,
};

export default None;
