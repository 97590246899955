import React from 'react';
import PropTypes from 'prop-types';

import { renderBlocks } from 'components/blocks/';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import algoliasearch from 'algoliasearch';

import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { algoliaAppId, algoliaIndex, algoliaSearchKey } = publicRuntimeConfig;
const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

import styles from './Contact.module.scss';

const Contact = ({ template: { blocks } }) => {
    return (
        <div className={styles.root}>
            {renderBlocks(blocks.slice(0, 1))}
            <InstantSearch searchClient={searchClient} indexName={algoliaIndex}>
                {renderBlocks(blocks.slice(1))}
            </InstantSearch>
        </div>
    );
};

Contact.propTypes = {
    blocks: PropTypes.array,
    contentTypeId: PropTypes.string,
    metaDescription: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string.isRequired,
                title: PropTypes.string,
            }).isRequired
        ),
        contentTypeId: PropTypes.string,
    }),
    seoTitle: PropTypes.string.isRequired,
};

Contact.defaultProps = {
    template: { blocks: [] },
};

export default Contact;
