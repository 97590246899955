import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { useClickAway } from 'react-use';

import Img from 'components/ui/Img';
import ButtonCircle from 'components/ui/ButtonCircle';
import Link from 'components/ui/Link';
import Text from 'components/ui/Text';

import styles from './BlockProductNav.module.scss';

const Nav = ({ asPath, navItems, transition, onTransitionEnd }) => {
    return (
        <nav className={styles.root} onTransitionEnd={onTransitionEnd}>
            {transition !== 'exited' && (
                <div className={styles.rootInner}>
                    {navItems.map((navItem, i) => (
                        <Link.Entry
                            key={navItem.id}
                            className={cx(styles.item, {
                                [styles.itemActive]: asPath
                                    ?.split('/')
                                    .includes(navItem.slug),
                            })}
                            style={{
                                '--animation-order': i + 1,
                            }}
                            entry={navItem}
                        >
                            <figure className={styles.aspectBox}>
                                <Img
                                    className={styles.image}
                                    src={navItem?.thumbnail?.file?.url}
                                    fallbackImageWidth={240}
                                    alt={navItem.title}
                                    width={
                                        navItem?.thumbnail?.file.details?.image
                                            ?.width
                                    }
                                    height={
                                        navItem?.thumbnail?.file.details?.image
                                            ?.height
                                    }
                                    customSources={[
                                        {
                                            breakpoint: 768,
                                            src: navItem?.thumbnail?.file?.url,
                                            imageWidth: 240,
                                        },
                                        {
                                            src: navItem?.thumbnail?.file?.url,
                                            imageWidth: 140,
                                        },
                                    ]}
                                    priority
                                />
                            </figure>
                            <Text
                                baseTheme="labelSmall"
                                themes={{ large: 'labelLarge' }}
                                className={styles.label}
                                as="figcaption"
                            >
                                {navItem.navLabel || navItem.title}
                            </Text>
                        </Link.Entry>
                    ))}
                </div>
            )}
        </nav>
    );
};

Nav.propTypes = {
    asPath: PropTypes.string,
    navItems: PropTypes.array.isRequired,
    transition: PropTypes.string,
    onTransitionEnd: PropTypes.func,
};

const BlockProductNav = ({ category, navItems, toggleMode }) => {
    const [transition, setTransition] = useState('exited');
    const router = useRouter();
    const menuRef = useRef(null);

    const closeMenu = () => {
        transition === 'entered' && setTransition('exiting');
    };

    useClickAway(menuRef, () => {
        closeMenu();
    });

    useEffect(() => {
        closeMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.asPath]);

    if (toggleMode) {
        return (
            <div
                key="productNav"
                ref={menuRef}
                className={cx(
                    styles.menu,
                    transition === 'entered' && styles.isEntered,
                    transition === 'exiting' && styles.isExiting,
                    transition === 'exited' && styles.isExited
                )}
            >
                <div
                    role="button"
                    className={styles.menuTrigger}
                    onClick={() => {
                        transition !== 'entered'
                            ? setTransition('entered')
                            : setTransition('exiting');
                    }}
                >
                    <Text baseTheme="headingXSmall">
                        {category.navLabel || category.title}
                    </Text>
                    <ButtonCircle
                        className={styles.buttonCircle}
                        theme="chevronDownCreamDark"
                    />
                </div>
                <Nav
                    navItems={navItems}
                    asPath={router.asPath}
                    transition={transition}
                    onTransitionEnd={() => {
                        transition === 'exiting' && setTransition('exited');
                    }}
                />
            </div>
        );
    }
    return <Nav navItems={navItems} />;
};

BlockProductNav.propTypes = {
    category: PropTypes.shape({
        navLabel: PropTypes.string,
        slug: PropTypes.string,
        title: PropTypes.string,
    }),
    currentCategory: PropTypes.string,
    navItems: PropTypes.array,
    toggleMode: PropTypes.bool,
};

BlockProductNav.defaultProps = {
    currentCategory: '',
    navItems: [],
    toggleMode: false,
};

export default BlockProductNav;
