import React from 'react';
import PropTypes from 'prop-types';

import { renderBlocks } from 'components/blocks/';
import NewsList from './NewsList';

import {
    fetchAllNews,
    useNormalizedContentfulResponse,
} from 'services/contentful';
import { Head } from 'components/globals/Head';

import styles from './News.module.scss';

const News = ({
    articles: newsResponse,
    template: { blocks },
    contentTypeAttributes,
    ...rest
}) => {
    const articles = useNormalizedContentfulResponse(newsResponse);
    const newsAttributes = contentTypeAttributes?.news || {};

    return (
        <div className={styles.newsPage}>
            <Head {...rest} />
            {renderBlocks(blocks)}
            {articles?.length && (
                <NewsList articles={articles} newsAttributes={newsAttributes} />
            )}
        </div>
    );
};

News.getStaticTemplateProps = async () => {
    return {
        articles: (await fetchAllNews()) || [],
    };
};

News.propTypes = {
    articles: PropTypes.object.isRequired,
    blocks: PropTypes.array,
    contentTypeId: PropTypes.string,
    metaDescription: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string.isRequired,
                title: PropTypes.string,
            }).isRequired
        ),
        contentTypeId: PropTypes.string,
    }),
    seoTitle: PropTypes.string.isRequired,
    contentTypeAttributes: PropTypes.object.isRequired,
};

News.defaultProps = {
    template: { blocks: [] },
    articles: [],
    contentTypeAttributes: {},
};

export default News;
