import React, { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'components/ui/Icon';
import ButtonCircle from 'components/ui/ButtonCircle';
import Text from 'components/ui/Text';
import Link from 'components/ui/Link';

import useScrollDirection from 'hooks/useScrollDirection';

import styles from './CommitmentSubNav.module.scss';

const CommitmentSubNav = ({ currentPage, idx, pages }) => {
    const [isExpanded, toggleExpanded] = useState(false);
    const scrollDirection = useScrollDirection();
    const ref = useRef(null);
    const router = useRouter();

    const updateExpanded = () => {
        toggleExpanded(!isExpanded);
    };

    function handleOutsideClick(event) {
        if (ref?.current && !ref?.current.contains(event.target)) {
            toggleExpanded(false);
        }
    }

    function toggleEventListener(method) {
        document[`${method}EventListener`]('click', handleOutsideClick);
    }

    useEffect(() => {
        if (scrollDirection === 'down') {
            isExpanded && toggleExpanded(false);
        }
    }, [isExpanded, scrollDirection]);

    useEffect(() => {
        toggleEventListener('add');

        return () => {
            toggleEventListener('remove');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        isExpanded && toggleExpanded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.asPath]);

    return (
        <header
            ref={ref}
            className={cx(styles.commitmentNavContainer, {
                [styles.isHidden]: scrollDirection === 'down',
            })}
        >
            <div
                className={cx(
                    styles.commitmentNav,
                    isExpanded && styles.isExpanded
                )}
            >
                <div
                    className={cx(
                        styles.currentPage,
                        isExpanded && styles.shrink
                    )}
                    onClick={updateExpanded}
                    role="button"
                >
                    <Text baseTheme="labelSmall">{`0${idx + 1}`}</Text>
                    <div className={styles.currentPageSeparator} />
                    <Text baseTheme="headingXSmall">{currentPage?.title}</Text>
                    <ButtonCircle
                        className={cx(
                            styles.buttonCircle,
                            isExpanded && styles.flip
                        )}
                        theme="chevronDownCreamDark"
                    />
                </div>
                <nav
                    className={cx(styles.nav, isExpanded && styles.isExpanded)}
                >
                    <ul className={styles.pageList}>
                        {pages.map((page, idx) => (
                            <li key={idx}>
                                <Link.Entry entry={page}>
                                    <div className={styles.page}>
                                        <div className={styles.iconBox}>
                                            <div
                                                className={cx(
                                                    styles.commitmentIconWrapper
                                                )}
                                            >
                                                <Icon
                                                    src={
                                                        page?.icon?.icon?.file
                                                            ?.url
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.pageLabel}>
                                            <Text
                                                className={
                                                    styles.indexIndicator
                                                }
                                                baseTheme="labelSmall"
                                            >
                                                0{idx + 1} -
                                            </Text>
                                            <Text baseTheme="headingXSmall">
                                                {page.title}
                                            </Text>
                                        </div>
                                        <ButtonCircle
                                            className={styles.pageArrow}
                                            theme="chevronRightCreamDark"
                                        />
                                    </div>
                                </Link.Entry>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </header>
    );
};

CommitmentSubNav.propTypes = {
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        }).isRequired
    ),
    currentPage: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        idx: PropTypes.number,
    }).isRequired,
    idx: PropTypes.number,
};

CommitmentSubNav.defaultProps = {
    pages: [],
};

export default CommitmentSubNav;
