import React, { Fragment } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { renderBlocks } from 'components/blocks/';

import { Title } from 'components/globals/Title';

const SharpnessStory = ({
    seoTitle,
    metaDescription,
    template: { blocks = [] },
}) => {
    return (
        <Fragment>
            <Title title={seoTitle} />
            <Head>
                {metaDescription && (
                    <meta
                        name="description"
                        key="description"
                        content={metaDescription}
                    />
                )}
            </Head>
            {renderBlocks(blocks)}
        </Fragment>
    );
};

SharpnessStory.propTypes = {
    contentTypeId: PropTypes.string,
    metaDescription: PropTypes.string,
    slug: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.array.isRequired,
        contentTypeId: PropTypes.string.isRequired,
        theme: PropTypes.string.isRequired,
    }).isRequired,
    seoTitle: PropTypes.string.isRequired,
};

export default SharpnessStory;
