import React, { useRef, useState, useEffect } from 'react';

import cx from 'classnames';

import PropTypes from 'prop-types';

import { Head } from 'components/globals/Head';
import Block, { renderBlocks } from 'components/blocks/';
import Text from 'components/ui/Text';
import ButtonCircle from 'components/ui/ButtonCircle';
import Link from 'components/ui/Link';
import Grid from 'components/ui/Grid';

import { useIntersection } from 'react-use';
import useScrollDirection from 'hooks/useScrollDirection';

import styles from './FoodServiceSubPage.module.scss';

const FoodServiceSubPage = ({
    template: {
        foodServiceCategory,
        masthead,
        productListBlocks,
        pageSubNav,
        contactBlock,
    },
    ...rest
}) => {
    const ref = useRef(null);
    const refSticky = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const scrollDirection = useScrollDirection();

    const intersection = useIntersection(refSticky, {
        rootMargin: '0px',
        threshold: 1,
    });

    useEffect(() => {
        if (intersection && intersection.intersectionRatio < 1) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    }, [intersection]);

    const handleOpenClick = () => {
        setIsOpen(true);
    };

    const handleCloseClick = () => {
        setIsOpen(false);
    };

    const handleOutsideClick = event => {
        if (ref?.current && !ref?.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        toggleEventListener('add');

        return () => {
            toggleEventListener('remove');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleEventListener = method => {
        document[`${method}EventListener`]('click', handleOutsideClick);
    };

    useEffect(() => {
        if (scrollDirection === 'down' || scrollDirection === 'up') {
            isOpen && setIsOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollDirection]);

    return (
        <div className={styles.foodServiceSubPageTemplate}>
            <Head {...rest} />
            {masthead && <Block {...masthead} contentTypeId="blockCallout" />}
            <section ref={ref} className={styles.subNavPage}>
                <Grid>
                    <div className={styles.sideNavContainer}>
                        <nav
                            ref={refSticky}
                            className={cx(styles.sideNav, {
                                [styles.isOpen]: isOpen,
                                [styles.isSticky]: isSticky,
                                [styles.isFlushTop]: scrollDirection === 'down',
                            })}
                        >
                            <div className={styles.sideNavAbsInner}>
                                <div
                                    className={styles.sideNavAbsInnerBack}
                                    onClick={handleCloseClick}
                                />
                                <div
                                    className={styles.sideNavHeader}
                                    onClick={handleOpenClick}
                                >
                                    {foodServiceCategory && (
                                        <Text
                                            as="span"
                                            baseTheme="labelLarge"
                                            className={styles.label}
                                        >
                                            {foodServiceCategory}
                                        </Text>
                                    )}
                                    <ButtonCircle
                                        className={styles.openButton}
                                        onClick={handleCloseClick}
                                        theme="chevronDownCreamDark"
                                    />
                                </div>
                                <div className={styles.sideNavInner}>
                                    {pageSubNav?.items.map((navItem, i) => {
                                        return (
                                            <div
                                                className={cx(
                                                    styles.sideNavGroup,
                                                    {
                                                        [styles.isActive]:
                                                            navItem.label ===
                                                            foodServiceCategory,
                                                    }
                                                )}
                                                key={i}
                                            >
                                                <Text
                                                    as="span"
                                                    baseTheme="labelLarge"
                                                    className={styles.label}
                                                >
                                                    <Link
                                                        href={`/${navItem.slug}`}
                                                    >
                                                        {navItem.label}
                                                    </Link>
                                                    {navItem.label ===
                                                        foodServiceCategory && (
                                                        <ButtonCircle
                                                            className={
                                                                styles.navCaret
                                                            }
                                                            theme="chevronDownCreamDark"
                                                        />
                                                    )}
                                                </Text>

                                                {navItem.label ===
                                                    foodServiceCategory &&
                                                    navItem?.blockGroup?.blocks.map(
                                                        (navBlockItem, i) => (
                                                            <div
                                                                className={cx(
                                                                    styles.sideLink
                                                                )}
                                                                key={i}
                                                            >
                                                                <Text
                                                                    key={i}
                                                                    as="h3"
                                                                    baseTheme="bodyMedium"
                                                                    themes={{
                                                                        medium: 'bodyMedium',
                                                                    }}
                                                                >
                                                                    <a
                                                                        href={`#${navBlockItem.anchorName}`}
                                                                    >
                                                                        {navBlockItem?.headline?.content.map(
                                                                            (
                                                                                line,
                                                                                i
                                                                            ) => {
                                                                                if (
                                                                                    line
                                                                                        .content[0]
                                                                                        .value ===
                                                                                    ''
                                                                                ) {
                                                                                    return null;
                                                                                }
                                                                                if (
                                                                                    i ===
                                                                                    navBlockItem
                                                                                        .headline
                                                                                        .content
                                                                                        .length -
                                                                                        1
                                                                                ) {
                                                                                    return `${line.content[0].value}`;
                                                                                }
                                                                                return `${line.content[0].value} `;
                                                                            }
                                                                        )}
                                                                    </a>
                                                                </Text>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        );
                                    })}
                                    <ButtonCircle
                                        className={styles.closeButton}
                                        onClick={handleCloseClick}
                                        theme="chevronDownCreamDark"
                                    />
                                </div>
                            </div>
                        </nav>
                    </div>
                    <section className={styles.blocks}>
                        {productListBlocks.blocks &&
                            renderBlocks(productListBlocks.blocks)}
                    </section>
                </Grid>
            </section>
            {contactBlock && (
                <Block
                    {...contactBlock}
                    contentTypeId="blockTwoColumnRichText"
                />
            )}
        </div>
    );
};

FoodServiceSubPage.propTypes = {
    metaDescription: PropTypes.string,
    template: PropTypes.shape({
        contentTypeId: PropTypes.string,
        foodServiceCategory: PropTypes.string,
        masthead: PropTypes.shape({
            eyebrow: PropTypes.string,
            headline: PropTypes.string,
            theme: PropTypes.string,
            colorTheme: PropTypes.string,
        }),
        productListBlocks: PropTypes.object,
        pageSubNav: PropTypes.object,
        contactBlock: PropTypes.object,
    }),
    seoTitle: PropTypes.string.isRequired,
};

export default FoodServiceSubPage;
