import React from 'react';
import PropTypes from 'prop-types';

import Block, { renderBlocks } from 'components/blocks/';

import CardCommitment from 'components/cards/CardCommitment';
import { Head } from 'components/globals/Head';

import styles from './Stewardship.module.scss';

const Stewardship = ({
    template: { blocks, commitmentCards, endBlocks, stewardshipReport },
    ...rest
}) => {
    return (
        <div className={styles.stewardshipPage}>
            <Head {...rest} />
            {renderBlocks(blocks)}
            {commitmentCards && (
                <article className={styles.commitmentCardsFlex}>
                    <div className={styles.commitmentCardsWrap}>
                        <ul className={styles.commitmentCards}>
                            {commitmentCards?.pages.map((card, i) => (
                                <li className={styles.card} key={i}>
                                    <CardCommitment {...card} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </article>
            )}
            {renderBlocks(endBlocks)}
            {stewardshipReport && (
                <Block
                    {...stewardshipReport}
                    contentTypeId="blockTwoColumnImage"
                />
            )}
        </div>
    );
};

Stewardship.propTypes = {
    blocks: PropTypes.array,
    commitmentCards: PropTypes.shape({
        pages: PropTypes.array,
    }),
    contentTypeId: PropTypes.string,
    endBlocks: PropTypes.array,
    metaDescription: PropTypes.string,
    pages: PropTypes.array,
    stewardshipReport: PropTypes.any,
    template: PropTypes.shape({
        blocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string.isRequired,
                title: PropTypes.string,
            }).isRequired
        ),
        commitmentCards: PropTypes.shape({
            pages: PropTypes.arrayOf(
                PropTypes.shape({
                    image: PropTypes.object.isRequired,
                    imageLarge: PropTypes.object.isRequired,
                    description: PropTypes.string.isRequired,
                    page: PropTypes.object.isRequired,
                }).isRequired
            ),
        }),
        contentTypeId: PropTypes.string,
        endBlocks: PropTypes.arrayOf(
            PropTypes.shape({
                contentTypeId: PropTypes.string,
                title: PropTypes.string,
            })
        ),
        stewardshipReport: PropTypes.shape({
            headline: PropTypes.string.isRequired,
            image: PropTypes.object.isRequired,
            imageLarge: PropTypes.object.isRequired,
        }),
    }),
    seoTitle: PropTypes.string.isRequired,
};

Stewardship.defaultProps = {
    template: {
        blocks: [],
        endBlocks: [],
        commitmentCards: { pages: [] },
        stewardshipReport: null,
    },
};

export default Stewardship;
