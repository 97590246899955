import React, { Fragment } from 'react';
import { Head } from 'components/globals/Head';

import PropTypes from 'prop-types';

import DefaultLayout from './layouts/Default';

const layouts = {
    Default: DefaultLayout,
};

const Default = props => {
    const LayoutComponent = layouts[props.template?.layout] || layouts.Default;
    return (
        <Fragment>
            <Head {...props} />
            <LayoutComponent {...props} />
        </Fragment>
    );
};

Default.propTypes = {
    contentTypeId: PropTypes.string,
    metaDescription: PropTypes.string,
    slug: PropTypes.string,
    template: PropTypes.shape({
        blocks: PropTypes.array.isRequired,
        contentTypeId: PropTypes.string.isRequired,
        layout: PropTypes.string.isRequired,
    }).isRequired,
    seoTitle: PropTypes.string.isRequired,
};

export default Default;
