import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';
import Icon from 'components/ui/Icon';
import Button from 'components/ui/Button';
import Stagger from 'components/ui/Stagger';

import styles from './JoinOurRestaurantsCTA.module.scss';

const JoinOurRestaurantsCTA = ({ headline, body, cta }) => {
    return (
        <div className={styles.root}>
            <Stagger className={styles.inner}>
                <Stagger.Child className={styles.icon} order={0}>
                    <Icon src="https://images.ctfassets.net/j8tkpy1gjhi5/1SxOYYrRS33hGq3IkwM8fS/a7967ddf7c9f0872cbbb6be133a74e0f/Chef.svg" />
                </Stagger.Child>
                <Stagger.Child className={styles.text} order={1}>
                    <Text
                        className={styles.headline}
                        baseTheme="displayXSmall"
                        themes={{ medium: 'displaySmall' }}
                    >
                        {headline}
                    </Text>

                    <Text
                        className={styles.body}
                        baseTheme="headingSmall"
                        themes={{ medium: 'bodyLarge' }}
                    >
                        {body}
                    </Text>
                </Stagger.Child>

                <Stagger.Child order={2}>
                    <Button
                        href={cta.url}
                        theme="filledBlueHoverCream-ultrabold"
                    >
                        {cta.text}
                    </Button>
                </Stagger.Child>
            </Stagger>
        </div>
    );
};

JoinOurRestaurantsCTA.propTypes = {
    body: PropTypes.string,
    cta: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
    }),
    headline: PropTypes.string,
};

export default JoinOurRestaurantsCTA;
